import React from "react";
import heroThumbOne from "../../assets/images/hero-thumb-1cb.png";
import heroThumbTwo from "../../assets/images/hero-thumb-2.png";
import shapeTwo from "../../assets/images/shape/shape-2.png";
import shapeThree from "../../assets/images/shape/shape-3.png";
import shapeFour from "../../assets/images/shape/shape-4.png";
import { Link } from "react-router-dom";
function HeroHomeOne() {
	return (
		<>
			<section className="appie-hero-area">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="appie-hero-content">
								<h1 className="appie-title">
									Lost money as a result of internet fraud?
								</h1>
								<p>
									The digital world is full with fraudsters, which is why we are
									here to combat frauds and successfully pursue recovery claims
									on behalf of victims.
								</p>
								<ul>
									<li>
										<Link to="/start" className="main-btn">
											Connect With Us
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="appie-hero-thumb">
								<div
									className="thumb wow animated fadeInUp"
									data-wow-duration="2000ms"
									data-wow-delay="200ms"
								>
									<img src={heroThumbOne} alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="hero-shape-1">
					<img src={shapeTwo} alt="" />
				</div>
				<div className="hero-shape-2">
					<img src={shapeThree} alt="" />
				</div>
				<div className="hero-shape-3">
					<img src={shapeFour} alt="" />
				</div>
			</section>
		</>
	);
}

export default HeroHomeOne;
