import React, { Component } from "react";
import Disqus from "disqus-react";

export default class HomeDisqus extends Component {
	render() {
		const disqusShortname = "suavereconn-org";
		const disqusConfig = {
			url: "http://localhost:3000",
			identifier: "dsq-count-scr",
			title: "Suavereconn",
		};

		return (
			<div className="article-container">
				<Disqus.DiscussionEmbed
					shortname={disqusShortname}
					config={disqusConfig}
				/>
			</div>
		);
	}
}
