import React, { useEffect } from "react";
import useToggle from "../../Hooks/useToggle";
import StickyMenu from "../../lib/StickyMenu";
import BackToTop from "../BackToTop";
import FooterHomeThree from "../HomeThree/FooterHomeThree";
import ProjectHomeThree from "../HomeThree/ProjectHomeThree";
import Drawer from "../Mobile/Drawer";
import FacebookScamService from "./FacebookScamService";
import HeaderService from "./HeaderService";
import HeroService from "./HeroService";

function FacebookScam() {
	useEffect(() => {
		StickyMenu();
	});
	const [drawer, drawerAction] = useToggle(false);
	return (
		<>
			<Drawer drawer={drawer} action={drawerAction.toggle} />
			<HeaderService action={drawerAction.toggle} />
			<HeroService />
			<FacebookScamService />
			<ProjectHomeThree />
			<FooterHomeThree />
			<BackToTop />
		</>
	);
}

export default FacebookScam;
