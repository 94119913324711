import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

function Drawer({ drawer, action }) {
	const [itemOpen, setOpen] = useState(false);
	const [itemSize, setSize] = useState("0px");
	const [item, setItem] = useState("home");
	const handler = (e, value) => {
		e.preventDefault();
		const getItems = document.querySelectorAll(`#${value} li`).length;
		if (getItems > 0) {
			setSize(`${43 * getItems}px`);
			setItem(value);
		}
		setOpen(!itemOpen);
		if (itemOpen) {
			setSize(`0px`);
		}
	};
	return (
		<>
			<div
				onClick={(e) => action(e)}
				className={`off_canvars_overlay ${drawer ? "active" : ""}`}
			></div>
			<div className="offcanvas_menu">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<div
								className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
							>
								<div className="canvas_close">
									<a style={{ cursor: "pointer" }} onClick={(e) => action(e)}>
										<i className="fa fa-times"></i>
									</a>
								</div>
								<div className="offcanvas-brand text-center mb-40">
									logo
									{/* <img src={logo} alt="" /> */}
								</div>
								<div id="menu" className="text-left ">
									<ul className="offcanvas_main_menu">
										<li>
											<Link to="/">Home</Link>
										</li>
										<li
											onClick={(e) => handler(e, "service")}
											id="service"
											className="menu-item-has-children active"
										>
											<span className="menu-expand">
												<i className="fa fa-angle-down"></i>
											</span>
											<a style={{ cursor: "pointer" }}>Service</a>
											<ul
												className="sub-menu"
												style={{
													height: item === "service" ? itemSize : "0px",
												}}
											>
												<li>
													<Link to="/service/wallet-fund-tracing-solutions">
														Wallet Fund Tracing Solutions
													</Link>
												</li>
												<li>
													<Link to="/service/bitcoin-scam-recovery">
														Bitcoin Scam Recovery
													</Link>
												</li>
												<li>
													<Link to="/service/charge-back-assistance">
														Charge Back Assistance{" "}
													</Link>
												</li>
												<li>
													<Link to="/service/due-diligence">
														Due Diligence{" "}
													</Link>
												</li>
												<li>
													<Link to="/service/debt-settlement-services">
														Debt Settlement Services
													</Link>
												</li>
												<li>
													<Link to="/service/fund-recovery-services">
														Fund Recovery Services
													</Link>
												</li>
												<li>
													<Link to="/service/cryptocurrency-scam-recovery-services">
														Cryptocurrency Scam Recovery Services
													</Link>
												</li>
												<li>
													<Link to="/service/facebook-scam-recovery">
														Facebook scam Recovery
													</Link>
												</li>
												<li>
													<Link to="/service/telegram-scam-recovery">
														Telegram scam Recovery
													</Link>
												</li>
												<li>
													<Link to="/service/instagram-investment-scam-recovery">
														Instagram Investment Scam Recovery
													</Link>
												</li>
												<li>
													<Link to="/service/online-romance-scam-recovery">
														Online Romance Scam Recovery
													</Link>
												</li>
												<li>
													<Link to="/service/how-to-find-a-legit-investment-firm">
														How To Find A Legit Investment Firm?
													</Link>
												</li>
											</ul>
										</li>
										<li
											onClick={(e) => handler(e, "about")}
											id="about"
											className="menu-item-has-children active"
										>
											<li>
												<Link to="/about-us">About Us</Link>
											</li>
										</li>
										<li
											onClick={(e) => handler(e, "scams")}
											id="scams"
											className="menu-item-has-children active"
										>
											<span className="menu-expand">
												<i className="fa fa-angle-down"></i>
											</span>
											<a style={{ cursor: "pointer" }}>Scams</a>
											<ul
												className="sub-menu"
												style={{
													height: item === "scams" ? itemSize : "0px",
												}}
											>
												<li>
													<Link to="/scams/money-transfer-scams">
														Money Transfer Scams
													</Link>
												</li>
												<li>
													<Link to="/scams/metatrader-scams">
														Metatrader Scams
													</Link>
												</li>
												<li>
													<Link to="/scams/facebook-lottery-scams">
														Facebook Lottery Scams{" "}
													</Link>
												</li>
												<li>
													<Link to="/scams/email-scams">Email Scams</Link>
												</li>
												<li>
													<Link to="/scams/sweepstakes-scams">
														Sweepstakes Scams
													</Link>
												</li>
												<li>
													<Link to="/scams/online-banking-scams">
														Online Banking Scams
													</Link>
												</li>
												<li>
													<Link to="/scams/investment-scams">
														Investment Scams
													</Link>
												</li>
												<li>
													<Link to="/scams/insurance-scams">
														Insurance Scams
													</Link>
												</li>
												<li>
													<Link to="/scams/phishing-scams">Phishing Scams</Link>
												</li>
												<li>
													<Link to="/scams/forex-withdrawal-scams">
														Forex Withdrawal Scams
													</Link>
												</li>
												<li>
													<Link to="/scams/ico-scams">Ico Scams</Link>
												</li>
												<li>
													<Link to="/scams/cfd-scams">Cfd Scams</Link>
												</li>
												<li>
													<Link to="/scams/pyramid-scams">Pyramid Scams</Link>
												</li>
												<li>
													<Link to="/scams/forex-scams">Forex Scams</Link>
												</li>
												<li>
													<Link to="/scams/broker-scams">Broker Scams</Link>
												</li>
											</ul>
										</li>
										<li
											onClick={(e) => handler(e, "blog")}
											id="blog"
											className="menu-item-has-children active"
										>
											<li>
												<Link to="/news">News</Link>
											</li>
										</li>
										<li
											onClick={(e) => handler(e, "contact")}
											id="contact"
											className="menu-item-has-children active"
										>
											<Link to="/contact">Contact</Link>
										</li>
										<li
											onClick={(e) => handler(e, "analysistool")}
											id="analysistool"
											className="menu-item-has-children active"
										>
											<Link to="/analysistool">Analysis Tool</Link>
										</li>
										<li
											onClick={(e) => handler(e, "scamsites")}
											id="scamsites"
											className="menu-item-has-children active"
										>
											<Link to="/scamsites">Scam Sites</Link>
										</li>
									</ul>
								</div>
								<div className="footer-widget-info">
									<ul>
										<li>
											<a href="mailto:info@polygondecipher.org">
												<i className="fal fa-envelope"></i>
												info@polygondecipher.org
											</a>
										</li>
										<li>
											<a href="tel:+1-617-380-3487">
												<i className="fal fa-phone"></i>
												+1 617 380 3487
											</a>
										</li>
										<li>
											<a>
												<i className="fal fa-map-marker-alt"></i> 27 Wormwood
												St, South Boston, Boston, MA, 02210 United States.
											</a>
										</li>
									</ul>
									<br />
									<Link to="/start" className="main-btn ml-30">
										Start Reclaim!
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Drawer;
