import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-1.png";

function FooterHomeThree({ className }) {
	const currentYear = new Date().getFullYear();
	return (
		<>
			<section className={`appie-footer-area appie-footer-3-area ${className}`}>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6">
							<div className="footer-about-widget footer-about-widget-3">
								<div className="social mt-30">
									<h4 className="title">Socials</h4>
									<br />
									<ul>
										<li>
											<a href="#">
												<i className="fab fa-facebook-f" />
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-twitter" />
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-instagram" />
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-linkedin-in" />
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="footer-navigation footer-navigation-3">
								<h4 className="title">Links</h4>
								<ul>
									<li>
										<Link to="/about-us">About Us</Link>
									</li>
									<li>
										<Link to="/terms">{`Terms & Conditions`}</Link>
									</li>
									<li>
										<Link to="/privacy">Privacy Policy</Link>
									</li>
									<li>
										<a href="#scams">Scams</a>
									</li>
									<li>
										<Link to="/scamsites">Scam Sites</Link>
									</li>
									<li>
										<Link to="/analysistool">Analysis Tool</Link>
									</li>
									<li>
										<Link to="/service/how-to-find-a-legit-investment-firm">
											How To Find A Legit Investment Firm?
										</Link>
									</li>
									<li>
										<Link to="/contact">Contact</Link>
									</li>
									<li>
										<Link to="/news">Blog</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="footer-navigation footer-navigation-3">
								<h4 className="title">Services</h4>
								<ul>
									<li>
										<Link to="/service/wallet-fund-tracing-solutions">
											Wallet Fund Tracing Solutions
										</Link>
									</li>
									<li>
										<Link to="/service/bitcoin-scam-recovery">
											Bitcoin Scam Recovery
										</Link>
									</li>
									<li>
										<Link to="/service/charge-back-assistance">
											Charge Back Assistance{" "}
										</Link>
									</li>
									<li>
										<Link to="/service/due-diligence">Due Diligence </Link>
									</li>
									<li>
										<Link to="/service/debt-settlement-services">
											Debt Settlement Services
										</Link>
									</li>
									<li>
										<Link to="/service/fund-recovery-services">
											Fund Recovery Services
										</Link>
									</li>
									<li>
										<Link to="/service/cryptocurrency-scam-recovery-services">
											Cryptocurrency Scam Recovery Services
										</Link>
									</li>
									<li>
										<Link to="/service/facebook-scam-recovery">
											Facebook scam Recovery
										</Link>
									</li>
									<li>
										<Link to="/service/telegram-scam-recovery">
											Telegram scam Recovery
										</Link>
									</li>
									<li>
										<Link to="/service/instagram-investment-scam-recovery">
											Instagram Investment Scam Recovery
										</Link>
									</li>
									<li>
										<Link to="/service/online-romance-scam-recovery">
											Online Romance Scam Recovery
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="footer-widget-info">
								<h4 className="title">Get In Touch</h4>
								<ul>
									<li>
										<a href="mailto:info@polygondecipher.org">
											<i className="fal fa-envelope" /> info@suavereconn.org
										</a>
									</li>
									<li>
										<a href="tel:+1-617-380-3487">
											<i className="fal fa-phone" />
											+1 (617) 380 3487
										</a>
									</li>
									<li>
										<a>
											<i className="fal fa-location-arrow" />
											27 Wormwood St, South Boston, Boston, MA, 02210 United
											States.
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div
								className="
                footer-copyright
                d-flex
                align-items-center
                justify-content-between
                pt-35
              "
							>
								<div className="copyright-text">
									<p>
										Copyright © {currentYear} Suavereconn. All rights reserved.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default FooterHomeThree;
