import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-1.png";

function FooterHomeOne({ className }) {
	return (
		<>
			<section className={`appie-footer-area ${className || ""}`}>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6">
							<div className="footer-about-widget">
								<h4 className="title">Socials</h4>
								<div className="social mt-30">
									<ul>
										<li>
											<a href="#">
												<i className="fab fa-facebook-f" />
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-twitter" />
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-instagram" />
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-linkedin-in" />
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-6">
							<div className="footer-navigation">
								<h4 className="title">Support</h4>
								<ul>
									<li>
										<Link to="/about-us">About Us</Link>
									</li>
									<li>
										<Link to="/terms">{`Terms & Conditions`}</Link>
									</li>
									<li>
										<Link to="/privacy">Privacy Policy</Link>
									</li>
									<li>
										<Link to="/legal">Legal</Link>
									</li>
									<li>
										<Link to="/news">Blog</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="footer-navigation">
								<h4 className="title">Services</h4>
								<ul>
									<li>
										<Link to="/about-us">Ethical Hacking</Link>
									</li>
									<li>
										<a href="#">Forensic Investigation</a>
									</li>
									<li>
										<a href="#">Asset Recovery</a>
									</li>
									<li>
										<a href="#">Cyber Security</a>
									</li>
									<li>
										<Link to="/Contact">Ethical Hacking</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="footer-widget-info">
								<h4 className="title">Get In Touch</h4>
								<ul>
									<li>
										<a href="mailto:info@polygondecipher.org">
											<i className="fal fa-envelope" /> info@polygondecipher.org
										</a>
									</li>
									<li>
										<a href="tel:+1-617-380-3487">
											<i className="fal fa-phone" />
											+1 (617) 380 3487
										</a>
									</li>
									<li>
										<a href="#">
											<i className="fal fa-location-arrow" />
											27 Wormwood St, South Boston, Boston, MA, 02210 United
											States.
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default FooterHomeOne;
