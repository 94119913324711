// import React, { useState } from 'react';
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../BackToTop";
import TestimonialHomeOne from "../HomeOne/TestimonialHomeOne";
import DownloadHomeThree from "../HomeThree/DownloadHomeThree";
import ServicesHomeThree from "../HomeThree/ServicesHomeThree";
import AboutHomeTwo from "../HomeTwo/AboutHomeTwo";
import FeaturesHomeTwo from "../HomeTwo/FeaturesHomeTwo";
import SponserHomeTwo from "../HomeTwo/SponserHomeTwo";
import BlogHomeOne from "../HomeOne/BlogHomeOne";
import Blog from "../Blog/Blog";
import Drawer from "../Mobile/Drawer";
import FooterHomeThree from "../HomeThree/FooterHomeThree";
import HeaderHomeFive from "./HeaderHomeFive";
import HeroHomeFive from "./HeroHomeFive";
import FeaturesHomeOne from "../HomeOne/FeaturesHomeOne";
import ProjectHomeThree from "../HomeThree/ProjectHomeThree";
import HeroHomeOne from "../HomeOne/HeroHomeOne";
import FaqHomeOne from "../HomeOne/FaqHomeOne";
import TrafficHomeOne from "../HomeOne/TrafficHomeOne";
import ServicesHomeOne from '../HomeOne/ServicesHomeOne';

function HomeFive() {
	const [drawer, drawerAction] = useToggle(false);
	return (
		<>
			<Drawer drawer={drawer} action={drawerAction.toggle} />
			<HeaderHomeFive action={drawerAction.toggle} />
			<HeroHomeOne />
			<ServicesHomeThree />
			<TrafficHomeOne />
            <ServicesHomeOne className="pt-50" />
			<FeaturesHomeTwo />
			<FaqHomeOne />
			<TestimonialHomeOne />
			{/* <SponserHomeTwo className="pt-90" /> */}
			<BlogHomeOne />
			<ProjectHomeThree />
			<FooterHomeThree />
			<BackToTop className="back-to-top-5" />
		</>
	);
}

export default HomeFive;
