import React from "react";
import thumb from "../../assets/images/traffic-thumb.png";
import { Link } from "react-router-dom";

function TrafficHomeOne() {
	return (
		<section className="appie-traffic-area pt-50 pb-180">
			<div className="container">
				<div className="row">
					<div className="col-lg-7">
						<div className="appie-traffic-title">
							<span>About Us</span>
							<h3 className="title pt-20">
								We help you, we lead you, and we recover your money.
							</h3>
							<p>
								We get ready to prevail. Our professionals manage your financial
								issues with promptness, transparency, and careful planning. We
								successfully combat fraud in the forex, binary options, and
								cryptocurrency marketplaces, among others.
							</p>
						</div>
						<div className="row">
							<div className="col-lg-6 col-md-6">
								<div className="appie-traffic-service mb-30">
									<div className="icon">
										<i className="fal fa-check" />
									</div>

									<p>Answers that are clear and timely.</p>
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="appie-traffic-service mb-30">
									<div className="icon">
										<i className="fal fa-check" />
									</div>
									<p>Has a 90% success rate worldwide.</p>
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="appie-traffic-service">
									<div className="icon">
										<i className="fal fa-check" />
									</div>
									<p>Experts in Digital Forensics</p>
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="appie-traffic-service">
									<div className="icon">
										<i className="fal fa-check" />
									</div>
									<p>Recovering money with unwavering quality .</p>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="traffic-btn mt-50">
									<Link to="/about-us" className="main-btn">
										Learn More <i className="fal fa-arrow-right" />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="traffic-thumb ">
				<img
					className="wow animated fadeInRight"
					data-wow-duration="2000ms"
					data-wow-delay="200ms"
					src={thumb}
					alt=""
				/>
			</div>
		</section>
	);
}

export default TrafficHomeOne;
