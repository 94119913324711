import React from "react";
import featureThumb from "../../assets/images/features-thumb-2.png";
import shape13 from "../../assets/images/shape/shape-13.png";
import shape14 from "../../assets/images/shape/shape-14.png";
import shape15 from "../../assets/images/shape/shape-15.png";

function FeaturesHomeTwo() {
	return (
		<>
			<section className="appie-features-area-2 pt-90 pb-100" id="features">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="appie-section-title appie-section-title-2 text-center">
								<h3>
									Several Scams We Can Assist You With
									<br />
								</h3>
								<p>
									It's amazing how many different methods scammers may use to
									steal your money. Internet frauds flooded the market and
									shocked some consumers and traders with their cunning schemes.
									We can help you out of it, regardless of how you got into
									it—whether you accidentally used your credit card for the
									wrong transaction, fell victim to a CFD fraud or an
									unanticipated online scam.
								</p>
							</div>
						</div>
					</div>
					<div className="row mt-30 align-items-center">
						<div className="col-lg-6">
							<div className="appie-features-boxes">
								<div className="appie-features-box-item">
									<h4 className="title">Broker Scams</h4>
									<p>
										Brokers are intended to assist with trading, not to be taken
										advantage of.
									</p>
								</div>
								<div className="appie-features-box-item item">
									<h4 className="title">Cfd Scams</h4>
									<p>Safe trading on underlying assets.</p>
								</div>
								<div className="appie-features-box-item item">
									<h4 className="title">Email Scams</h4>
									<p>Send emails freely but stay away from maliciouslinks. </p>
								</div>
								<div className="appie-features-box-item item">
									<h4 className="title">Forex Withdrawal Scams</h4>
									<p>Exchange currencies, but avoid fraudulent transactions.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="appie-features-boxes">
								<div className="appie-features-box-item">
									<h4 className="title">Investment Scams</h4>
									<p>
										Should be a secure platform where you make investments and
										profit
									</p>
								</div>
								<div className="appie-features-box-item item">
									<h4 className="title">Online Banking Scams</h4>
									<p>
										Banks ought to be protected, trusted, safe and secure, but
										not feared.
									</p>
								</div>
								<div className="appie-features-box-item item">
									<h4 className="title">Phishing Scams</h4>
									<p>
										Check the URL before entering your information to ensure a
										secure transaction.{" "}
									</p>
								</div>
								<div className="appie-features-box-item item">
									<h4 className="title">Sweepstakes Scams</h4>
									<p>
										Lotteries offer numerous chances to contribute back to
										communities by way of prizes and not the other way around.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default FeaturesHomeTwo;
