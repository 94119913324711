import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboutUs from "./components/AboutUs";
import Privacy from "./components/AboutUs/Privacy";
import Terms from "./components/AboutUs/Terms";
import Legal from "./components/AboutUs/Legal";
import Contact from "./components/Contact";
import Error from "./components/Error";
import Loader from "./components/Helper/Loader";
import ScrollToTop from "./components/Helper/ScrollToTop";
import HomeFive from "./components/HomeFive";
import News from "./components/News";
import ShowBlog from "./components/Blog/ShowBlog";
import SingleNews from "./components/News/SingleNews";
import AssetRecovery from "./components/Service/WalletFund";
import EthicalHacking from "./components/Service/ChargeBack";
import ForensicInvestigation from "./components/Service/DueDiligence";
import CyberSecurity from "./components/Service/BitcoinScam";
import StartForm from "./components/StartForm";
import WalletFund from "./components/Service/WalletFund";
import BitcoinScam from "./components/Service/BitcoinScam";
import ChargeBack from "./components/Service/ChargeBack";
import DueDiligence from "./components/Service/DueDiligence";
import DebtSettlement from "./components/Service/DebtSettlement";
import FundRecovery from "./components/Service/FundRecovery";
import CryptocurrencyScam from "./components/Service/CryptocurrencyScam";
import FacebookScam from "./components/Service/FacebookScam";
import Telegramscam from "./components/Service/Telegramscam";
import InstagramInvestment from "./components/Service/InstagramInvestment";
import OnlineRomance from "./components/Service/OnlineRomance";
import HowToFind from "./components/Service/HowToFind";
import MoneyTransfer from "./components/Scams/MoneyTransfer";
import MetaTrader from "./components/Scams/MetaTrader";
import FacebookLottery from "./components/Scams/FacebookLottery";
import Email from "./components/Scams/Email";
import OnlineBanking from "./components/Scams/OnlineBanking";
import SweepStakes from "./components/Scams/SweepStakes";
import Investment from "./components/Scams/Investment";
import Insurance from "./components/Scams/Insurance";
import Phishing from "./components/Scams/Phishing";
import Forex from "./components/Scams/Forex";
import Ico from "./components/Scams/Ico";
import Cfo from "./components/Scams/Cfo";
import Pyramid from "./components/Scams/Pyramid";
import ForexWithdrawal from "./components/Scams/ForexWithdrawal";
import Broker from "./components/Scams/Broker";
import AnalysisTool from "./components/AnalysisTools/Analysis";
import ScamSites from "./components/ScamSites/scamSites";
import Scaminfo from "./components/ScamSites/Scaminfo";

function Routes() {
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		window.scrollTo(0, 0);
	});
	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	});
	return (
		<>
			{loading && (
				<div className={`appie-loader ${loading ? "active" : ""}`}>
					<Loader />
				</div>
			)}
			<div className={`appie-visible ${loading === false ? "active" : ""}`}>
				<Router>
					<ScrollToTop>
						<Switch>
							<Route exact path="/" component={HomeFive} />
							<Route exact path="/news" component={News} />
							<Route path="/news/:id" component={SingleNews} />
							<Route exact path="/about-us" component={AboutUs} />
							<Route exact path="/privacy" component={Privacy} />
							<Route exact path="/terms" component={Terms} />
							<Route exact path="/legal" component={Legal} />
							<Route exact path="/contact" component={Contact} />
							<Route exact path="/start" component={StartForm} />
							<Route exact path="/analysistool" component={AnalysisTool} />
							<Route exact path="/scamsites" component={ScamSites} />
							<Route exact path="/scamsites/info" component={Scaminfo} />
							{/* services */}
							<Route
								exact
								path="/service/wallet-fund-tracing-solutions"
								component={WalletFund}
							/>
							<Route
								exact
								path="/service/bitcoin-scam-recovery"
								component={BitcoinScam}
							/>
							<Route
								exact
								path="/service/charge-back-assistance"
								component={ChargeBack}
							/>
							<Route
								exact
								path="/service/due-diligence"
								component={DueDiligence}
							/>
							<Route
								exact
								path="/service/debt-settlement-services"
								component={DebtSettlement}
							/>
							<Route
								exact
								path="/service/fund-recovery-services"
								component={FundRecovery}
							/>
							<Route
								exact
								path="/service/cryptocurrency-scam-recovery-services"
								component={CryptocurrencyScam}
							/>
							<Route
								exact
								path="/service/facebook-scam-recovery"
								component={FacebookScam}
							/>
							<Route
								exact
								path="/service/telegram-scam-recovery"
								component={Telegramscam}
							/>
							<Route
								exact
								path="/service/instagram-investment-scam-recovery"
								component={InstagramInvestment}
							/>
							<Route
								exact
								path="/service/online-romance-scam-recovery"
								component={OnlineRomance}
							/>
							<Route
								exact
								path="/service/how-to-find-a-legit-investment-firm"
								component={HowToFind}
							/>
							{/* End services */}

							{/* Scams */}
							<Route
								exact
								path="/scams/money-transfer-scams"
								component={MoneyTransfer}
							/>
							<Route
								exact
								path="/scams/metatrader-scams"
								component={MetaTrader}
							/>
							<Route
								exact
								path="/scams/facebook-lottery-scams"
								component={FacebookLottery}
							/>
							<Route exact path="/scams/email-scams" component={Email} />
							<Route
								exact
								path="/scams/online-banking-scams"
								component={OnlineBanking}
							/>
							<Route
								exact
								path="/scams/sweepstakes-scams"
								component={SweepStakes}
							/>
							<Route
								exact
								path="/scams/investment-scams"
								component={Investment}
							/>
							<Route
								exact
								path="/scams/insurance-scams"
								component={Insurance}
							/>
							<Route exact path="/scams/phishing-scams" component={Phishing} />
							<Route
								exact
								path="/scams/forex-withdrawal-scams"
								component={ForexWithdrawal}
							/>
							<Route exact path="/scams/ico-scams" component={Ico} />
							<Route exact path="/scams/cfd-scams" component={Cfo} />
							<Route exact path="/scams/pyramid-scams" component={Pyramid} />
							<Route exact path="/scams/forex-scams" component={Forex} />
							<Route exact path="/scams/broker-scams" component={Broker} />
							{/* End Scams */}
							<Route exact path="/error" component={Error} />
							<Route component={Error} />
						</Switch>
					</ScrollToTop>
				</Router>
			</div>
		</>
	);
}

export default Routes;
