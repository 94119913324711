import React, { useEffect } from "react";
import iconOne from "../../assets/images/icon/1.png";
import iconTwo from "../../assets/images/icon/2.png";
import iconThree from "../../assets/images/icon/3.png";
import iconFour from "../../assets/images/icon/4.png";
import iconFive from "../../assets/images/icon/5.png";
import { Link } from "react-router-dom";
function ServicesHomeThree() {
	return (
		<>
			<div className="growthF">
				<div className="container GF">
					<div className="growthHead appie-about-content" data-aos="fade-down">
						<h1 className="title">
							Our services help victims of financial instability quickly regain
							their wealth.
						</h1>
						<p>
							We are qualified to assist you in our capacities as a consultant,
							recovery agency, legal aid, or simply with support and
							information, regardless of whether you lost money while acting as
							a trader, shopper, or online user.
						</p>
					</div>
					<div className="growthBodyTop" data-aos="fade-right">
						<span className="growthContent">
							<img src={iconOne} alt="wallet" width="90px" />
							<h3>Bitcoin Scam Recovery</h3>
							<p>
								Our services help bitcoin traders recover the money that was
								misappropriated by unauthorized brokers or phony trading
								platforms.
							</p>
						</span>
						<span className="growthContent">
							<img src={iconTwo} alt="wallet" width="90px" />
							<h3>ChargeBack Assistance</h3>
							<p>
								Chargeback requests are accepted, and we may handle them for
								you. You are no longer responsible for managing disagreements
								with your merchant.
							</p>
						</span>
						<span className="growthContent">
							<img src={iconThree} alt="wallet" width="90px" />
							<h3>Due Diligence</h3>
							<p>
								We take careful care of important topics. We make the best
								judgments and stick to them thanks to careful study and
								analysis.
							</p>
						</span>
					</div>
					<div className="growthBodyBottom" data-aos="fade-left">
						<span className="growthContent">
							<img src={iconFour} alt="wallet" width="90px" />
							<h3>Debt Settlement Services</h3>
							<p className="ggold">
								We make it easier for you to settle the debts that have been
								weighing you down for so long. Stop arguing with your debtor
								anymore.
							</p>
						</span>
						<span className="growthContent">
							<img src={iconFive} width="90px" alt="wallet" />
							<h3>Wallet Fund Tracing Solutions</h3>
							<p>
								Our professionals will assist you in finding your wallet and
								regaining access, whether you lost it or were locked out.
							</p>
						</span>
					</div>
				</div>
			</div>
		</>
	);
}

export default ServicesHomeThree;
