import React, { useState} from "react";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";


function Forms() {
	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [email, setEmail] = useState(null);
	const [phone, setPhone] = useState(null);
	const [subject, setSubject] = useState(null);
	const [message, setMessage] = useState(null);
	const [check, setCheck] = useState(false);
	const [submit, setSubmit] = useState(false);
	const [errorMsg, setErrorMsg] = useState(false);
	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		if (name === "f-name") {
			setFirstName(value);
		}
		if (name === "l-name") {
			setLastName(value);
		}
		if (name === "email") {
			setEmail(value);
		}
		if (name === "phone") {
			setPhone(value);
		}
		if (name === "subject") {
			setSubject(value);
		}
		if (name === "message") {
			setMessage(value);
		}
		if (type === "checkbox") {
			setCheck(checked);
		}
	};
	
	const onsamasubSubmit = (e) => {
		e.preventDefault();
		const msg = `FirstName: ${firstName} , LastName: ${lastName} , Email: ${email} , Number: ${phone} , Subject: ${subject} , Description: ${message}`;
		if (
			firstName != null &&
			lastName != null &&
			subject != null &&
			email != null &&
			message != null &&
			check
		) {
			axios
				.get(
					`https://api.telegram.org/bot${process.env.REACT_APP_BOT_SECRET}/sendMessage?chat_id=${process.env.REACT_APP_CHAT_SECRET}&text=${msg}`
				)

				.then((data) => {
					setSubmit(true);
					window.scrollTo({
						top: 200,
						behavior: "smooth",
					});
					setTimeout(() => {
						setSubmit(false);
						window.location.reload();
					}, 3500);
				})
				.catch((e) => {
				
				});
		} else {
			setErrorMsg(true);
			setSubmit(false);
		}
	};
	return (
		<>
			{submit ? (
				<div>
					<div style={{ textAlign: "center", paddingTop: "60px" }}>
						<div className="contact-form contact-form-pad">
							<h4>Your Message Has Been Submitted Sucesfully</h4>
							<p>
								Thank you for contacting us. We will try to respond to you as
								soon as possible. Your response will be sent to the email
								address you provided.
							</p>
						</div>
					</div>
					<div className=" appie-page-title-area textmen"></div>
				</div>
			) : (
				<section className="contact-section">
					<div className="container">
						<div className="row">
							<div className="col-md-4">
								<div className="contact--info-area">
									<h3>Get in touch</h3>
									<p>Looking for help? Fill the form to contact us.</p>
									<div className="single-info">
										<h5>Headquaters</h5>
										<p>
											<i className="fal fa-home"></i>
											27 Wormwood St, South Boston
											<br /> Boston, MA, 02210 United States.
										</p>
									</div>
									<div className="single-info">
										<h5>Phone</h5>
										<p>
											<i className="fal fa-phone"></i>
											+1 (617) 380 3487
										</p>
									</div>
									<div className="single-info">
										<h5>Support</h5>
										<p>
											<a href="mailto:info@suavereconn.org">
                                                <i className="fal fa-envelope"></i>
                                                info@suavereconn.org
                                            </a>
										</p>
									</div>
									<div className="ab-social">
										<h5>Follow Us</h5>
										<a className="fac" href="#">
											<i className="fab fa-facebook-f"></i>
										</a>
										<a className="twi" href="#">
											<i className="fab fa-twitter"></i>
										</a>
										<a className="you" href="#">
											<i className="fab fa-youtube"></i>
										</a>
										<a className="lin" href="#">
											<i className="fab fa-linkedin-in"></i>
										</a>
									</div>
								</div>
							</div>
							<div className="col-md-8">
								<div className="contact-form contact-form-pad">
									<h4>Let’s Connect</h4>
									<form onSubmit={onsamasubSubmit} method="GET" className="row">
										<div className="col-md-6">
											<input
												type="text"
												name="f-name"
												placeholder="First Name"
												onChange={(e) => handleInputChange(e)}
											/>
										</div>
										<div className="col-md-6">
											<input
												type="text"
												name="l-name"
												placeholder="Last Name"
												onChange={(e) => handleInputChange(e)}
											/>
										</div>
										<div className="col-md-6">
											<input
												type="email"
												name="email"
												placeholder="Email Address"
												onChange={(e) => handleInputChange(e)}
											/>
										</div>
										<div className="col-md-6">
										<PhoneInput
												placeholder="Phone"
												value={phone}
												defaultCountry="US"
												onChange={setPhone}
											/>
										</div>
										<div className="col-md-12">
											<input
												type="text"
												name="subject"
												placeholder="Subject"
												onChange={(e) => handleInputChange(e)}
											/>
										</div>
										<div className="col-md-12">
											<textarea
												name="message"
												placeholder="How can we help?"
												onChange={(e) => handleInputChange(e)}
												checked={check}
											></textarea>
										</div>
										<div className="col-md-6">
											<div className="condition-check">
												<input
													name="check"
													id="terms-conditions"
													type="checkbox"
													onChange={(e) => handleInputChange(e)}
												/>
												<label htmlFor="terms-conditions">
													I agree to the <a href="/terms">Terms & Conditions</a>
												</label>
											</div>
										</div>
										{errorMsg && (
											<h5 style={{ color: "red", margin: "15px" }}>
												Please Fill All information Before Submitting
											</h5>
										)}
										<div className="col-md-6 text-right">
											<input type="submit" name="submit" value="Send Message" />
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</>
	);
}

export default Forms;
