import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import AnalysisD from "./AnalysisD";
import Loader from "../Helper/Loader";
function Atool() {
	const [analysis, setanalysis] = useState(null);
	const [domain, setdomain] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, seterror] = useState(null);
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		if (name === "analysis") {
			setdomain(value);
		}
	};
	const onsamasubSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		const options = {
			method: "GET",
			url: "https://ip2whois-whois-information-lookup.p.rapidapi.com/",
			params: {
				key: "5A0DD7F4B301AC41DBF47DBD33596413",
				domain: domain,
				format: "json",
			},
			headers: {
				"X-RapidAPI-Key": "925fa1ead1mshd30c99d956455acp1fbc2ajsn685a41b6d8b7",
				"X-RapidAPI-Host": "ip2whois-whois-information-lookup.p.rapidapi.com",
			},
		};

		axios
			.request(options)
			.then(function (response) {
				setanalysis(response.data);
				setLoading(false);
			})
			.catch(function (error) {
				seterror(error);
			});
	};
	const location = useLocation();
	return (
		<>
			{loading ? (
				<div className={`appie-loader ${loading ? "active" : ""}`}>
					<Loader />
				</div>
			) : (
				<>
					<section className="appie-service-details-area pt-100 pb-100">
						<div className="container">
							<div className="web_analysis">
								<h1 className="heads">Web Analysis Tool</h1>
								<p>
									The Web Analysis Tool provides you with the most basic
									information for identifying websites and applications, thereby
									providing insight into what kind of website you are
									interacting with, and if it is a scam site or not. All that is
									required for you to utilize this service is the business'
									website address. Enter the URL in the search bar, and the tool
									will provide all the necessary information.
								</p>
								<form onSubmit={onsamasubSubmit} method="GET">
									<div className="col-md-12 contact-form">
										<input
											type="text"
											name="analysis"
											placeholder="Enter Website Here"
											onChange={(e) => handleInputChange(e)}
										/>
										<input type="submit" name="submit" value="Scan Website" />
									</div>
								</form>
								<div className="analysis">
									{analysis && (
										<AnalysisD
											domain={analysis.domain}
											created={analysis.create_date}
											updated={analysis.update_date}
											expires={analysis.expire_date}
											nameservers={analysis.nameservers}
											admin={analysis.admin}
											country={analysis.registrant.country}
											region={analysis.registrant.region}
										/>
									)}
								</div>
								<div className="web_analysis">
									<h1>How to Spot a Scam Website</h1>
									<p>
										1. Domain Creation: Businesses that claim to have been
										established for several years must have a domain name
										registration that corresponds to that year unless they claim
										to have changed it. However, even in cases where names and
										other information have been changed as a result of internal
										disputes, such businesses are still recognized as
										legitimate. Note: For instance, if a firm claims to have
										been established in 2020 but hasn't made any changes to the
										domain, a registration alert will be raised. Also, keep in
										mind that only newly registered domains have their updated
										and created dates match; established domains have created
										and updated dates separated by at least two months.
									</p>
									<p>
										2. Country and Region: Legitimate businesses allow you to
										see their regions; any domain that only provides you with
										the country of registration is a red flag. The region
										provided must correspond to the location Head Office
										provided on the website. For example, country of
										registration: FL US. The above example contains its region
										which must match the address and zip from the website.
									</p>
									<p>
										3. DNS Details: Copy just one nameserver, for instance,
										ns1.dns-parking.com, and search on Google. It should show
										companies that provide the nameserver and must also be
										well-known companies like godaddy, namecheap, hostinger, or
										even their own company's nameservers, for example
										google.com, Nameservers:ns1.google.com. Organizations choose
										the best hosting, not some low-cost hosting or domain
										suppliers.
									</p>
									{analysis && (
										<div className="rawwho">
											<h5>Raw Whois:</h5>
											<h6>Domain: {analysis && analysis.domain}</h6>
											<h6>Domain id: {analysis && analysis.domain_id}</h6>
											<h6>Creation Date: {analysis && analysis.create_date}</h6>
											<h6>Updated Date: {analysis && analysis.update_date}</h6>
											<h6>
												Registry Expiry Date: {analysis && analysis.expire_date}
											</h6>
											<h6>Status: {analysis && analysis.status}</h6>
											<h6>Whois Server: {analysis && analysis.whois_server}</h6>
											<h6>Admin City: {analysis && analysis.admin.city}</h6>
											<h6>
												Admin country: {analysis && analysis.admin.country}
											</h6>
											<h6>Admin Email: {analysis && analysis.admin.email}</h6>
											<h6>Admin Fax: {analysis && analysis.admin.fax}</h6>
											<h6>Admin Name: {analysis && analysis.admin.name}</h6>
											<h6>
												Admin Organisation:{" "}
												{analysis && analysis.admin.organization}
											</h6>
											<h6>Admin Phone: {analysis && analysis.admin.phone}</h6>
											<h6>Admin Region: {analysis && analysis.admin.region}</h6>
											<h6>
												Admin Street Address:{" "}
												{analysis && analysis.admin.street_address}
											</h6>
											<h6>Admin Zip: {analysis && analysis.admin.zip_code}</h6>
											<h6>
												Registrant City: {analysis && analysis.registrant.city}
											</h6>
											<h6>
												Registrant country:{" "}
												{analysis && analysis.registrant.country}
											</h6>
											<h6>
												Registrant Email:{" "}
												{analysis && analysis.registrant.email}
											</h6>
											<h6>
												Registrant Fax: {analysis && analysis.registrant.fax}
											</h6>
											<h6>
												Registrant Name: {analysis && analysis.registrant.name}
											</h6>
											<h6>
												Registrant Organisation:{" "}
												{analysis && analysis.registrant.organization}
											</h6>
											<h6>
												Registrant Phone:{" "}
												{analysis && analysis.registrant.phone}
											</h6>
											<h6>
												Registrant Region:{" "}
												{analysis && analysis.registrant.region}
											</h6>
											<h6>
												Registrant Street Address:{" "}
												{analysis && analysis.registrant.street_address}
											</h6>
											<h6>
												Registrant Zip:{" "}
												{analysis && analysis.registrant.zip_code}
											</h6>
											<h6>
												Registrar iana id:{" "}
												{analysis && analysis.registrar.iana_id}
											</h6>
											<h6>
												Registrar Name: {analysis && analysis.registrar.name}
											</h6>
											<h6>
												Registrar Url: {analysis && analysis.registrar.url}
											</h6>
											<h6>Tech City: {analysis && analysis.tech.city}</h6>
											<h6>Tech country: {analysis && analysis.tech.country}</h6>
											<h6>Tech Email: {analysis && analysis.tech.email}</h6>
											<h6>Tech Fax: {analysis && analysis.tech.fax}</h6>
											<h6>Tech Name: {analysis && analysis.tech.name}</h6>
											<h6>
												Tech Organisation:{" "}
												{analysis && analysis.tech.organization}
											</h6>
											<h6>Tech Phone: {analysis && analysis.tech.phone}</h6>
											<h6>Tech Region: {analysis && analysis.tech.region}</h6>
											<h6>
												Tech Street Address:{" "}
												{analysis && analysis.tech.street_address}
											</h6>
											<h6>Tech Zip: {analysis && analysis.tech.zip_code}</h6>
											{analysis &&
												analysis.nameservers.map((items) => (
													<h6>NameServers: {items}</h6>
												))}
										</div>
									)}
								</div>
							</div>
						</div>
					</section>
				</>
			)}
		</>
	);
}

export default Atool;
