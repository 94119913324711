import React from "react";
import moment from "moment";
function AnalysisD({
	domain,
	created,
	updated,
	expires,
	nameservers,
	country,
	region,
}) {
	return (
		<div className="whoisitems">
			<h5>domain: {domain}</h5>
			<h5>created: {moment(created).format("dddd, MMMM Do YYYY,")}</h5>
			<h5>updated: {moment(updated).format("dddd, MMMM Do YYYY,")}</h5>
			<h5>expires: {moment(expires).format("dddd, MMMM Do YYYY,")}</h5>
			<h5>
				country of registration: {region !== "REDACTED FOR PRIVACY" && region}{" "}
				{country}
			</h5>
			<h5>
				NameServers:
				{nameservers && nameservers.map((items) => <h5>{items}</h5>)}
			</h5>
		</div>
	);
}

export default AnalysisD;
