import React from "react";
import { Link, useLocation } from "react-router-dom";

function HeroService() {
	const location = useLocation();
	const titleselect =
		location.pathname === "/service/wallet-fund-tracing-solutions"
			? "Wallet Fund Tracing Solutions"
			: location.pathname === "/service/bitcoin-scam-recovery"
			? `Bitcoin Scam Recovery`
			: location.pathname === "/service/charge-back-assistance"
			? "Charge Back Assistance"
			: location.pathname === "/service/due-diligence"
			? "Due Diligence"
			: location.pathname === "/service/debt-settlement-services"
			? `Debt Settlement Services`
			: location.pathname === "/service/fund-recovery-services"
			? "Fund Recovery Services"
			: location.pathname === "/service/cryptocurrency-scam-recovery-services"
			? `Cryptocurrency Scam Recovery Services`
			: location.pathname === "/service/facebook-scam-recovery"
			? "Facebook scam Recovery"
			: location.pathname === "/service/telegram-scam-recovery"
			? "Telegram scam Recovery"
			: location.pathname === "/service/instagram-investment-scam-recovery"
			? `Instagram Investment Scam Recovery`
			: location.pathname === "/service/online-romance-scam-recovery"
			? "Online Romance Scam Recovery"
			: location.pathname === "/service/how-to-find-a-legit-investment-firm"
			? "How To Find A Legit Investment Firm?"
			: location.pathname === "/scams/money-transfer-scams"
			? `Money Transfer Scams`
			: location.pathname === "/scams/metatrader-scams"
			? "Metatrader Scams"
			: location.pathname === "/scams/facebook-lottery-scams"
			? "Facebook Lottery Scams"
			: location.pathname === "/scams/email-scams"
			? `Email Scams`
			: location.pathname === "/scams/online-banking-scams"
			? "Online Banking Scams"
			: location.pathname === "/scams/sweepstakes-scams"
			? `Sweepstakes Scams`
			: location.pathname === "/scams/investment-scams"
			? "Investment Scams"
			: location.pathname === "/scams/insurance-scams"
			? "Insurance Scams"
			: location.pathname === "/scams/phishing-scams"
			? `Phishing Scams`
			: location.pathname === "/scams/forex-withdrawal-scams"
			? "Forex Withdrawal Scams"
			: location.pathname === "/scams/ico-scams"
			? `Ico Scams`
			: location.pathname === "/scams/cfd-scams"
			? "Cfd Scams"
			: location.pathname === "/scams/pyramid-scams"
			? `Pyramid Scams`
			: location.pathname === "/scams/forex-scams"
			? "Forex Scams"
			: location.pathname === "/scams/broker-scams" && "Broker Scams";
	return (
		<>
			<div className="appie-page-title-area appie-page-service-title-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="appie-page-title-item">
								<span>Home{location.pathname}</span>
								<h3 className="title">{titleselect}</h3>
								<br />
								<div>
									<a class="main-btn" href="/start">
										Recover your Funds Now
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default HeroService;
