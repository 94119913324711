import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import AnalysisD from "./Scaminfo";
function ScamList() {
	const [analysis, setanalysis] = useState(null);
	const [domain, setdomain] = useState(null);
	const [list, setlist] = useState([]);
	const [error, seterror] = useState(null);
	useEffect(() => {
		const options = {
			method: "GET",
			url: "https://suavereconn.org/backend/public/api/products/",
		};

		axios
			.request(options)
			.then(function (response) {
				setlist(response.data);
			})
			.catch(function (error) {
				seterror(error);
			});
	}, []);
	return (
		<>
			<section className="appie-service-details-area pt-100 pb-100">
				<div className="container">
					<div className="web_analysis">
						<h1 className="head">Forex Scammer List</h1>
						<p>
							Regrettably, there are more bogus forex scam brokers and other
							internet trading firms (including binary options) attempting to
							defraud unwary customers every day. Those who have been defrauded
							by forex scammers are listed in the table below.
							<br />
							Please click on the name of the broker in the list below to be
							sent to their complaint page if you believe that any of these
							brokers have defrauded you and would like to share your
							experience. You can fill out the form there and let us know how
							you were defrauded.
						</p>
					</div>
					<div className="scamlist">
						{list &&
							list.map((list) => {
								return (
									<Link
										to={{
											pathname: "/scamsites/info",
											state: { url: list.url },
										}}
									>
										{list.url}
									</Link>
								);
							})}
					</div>
				</div>
			</section>
		</>
	);
}

export default ScamList;
