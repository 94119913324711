import React from "react";
import { Link } from "react-router-dom";

function Navigation() {
	return (
		<>
			<ul>
				<li>
					<Link to="/">Home</Link>
				</li>
				<li>
					<a style={{ cursor: "pointer" }}>
						Service <i className="fal fa-angle-down" />
					</a>
					<ul className="sub-menu">
						<li>
							<Link to="/service/wallet-fund-tracing-solutions">
								Wallet Fund Tracing Solutions
							</Link>
						</li>
						<li>
							<Link to="/service/bitcoin-scam-recovery">
								Bitcoin Scam Recovery
							</Link>
						</li>
						<li>
							<Link to="/service/charge-back-assistance">
								Charge Back Assistance{" "}
							</Link>
						</li>
						<li>
							<Link to="/service/due-diligence">Due Diligence </Link>
						</li>
						<li>
							<Link to="/service/debt-settlement-services">
								Debt Settlement Services
							</Link>
						</li>
						<li>
							<Link to="/service/fund-recovery-services">
								Fund Recovery Services
							</Link>
						</li>
						<li>
							<Link to="/service/cryptocurrency-scam-recovery-services">
								Cryptocurrency Scam Recovery Services
							</Link>
						</li>
						<li>
							<Link to="/service/facebook-scam-recovery">
								Facebook scam Recovery
							</Link>
						</li>
						<li>
							<Link to="/service/telegram-scam-recovery">
								Telegram scam Recovery
							</Link>
						</li>
						<li>
							<Link to="/service/instagram-investment-scam-recovery">
								Instagram Investment Scam Recovery
							</Link>
						</li>
						<li>
							<Link to="/service/online-romance-scam-recovery">
								Online Romance Scam Recovery
							</Link>
						</li>
						<li>
							<Link to="/service/how-to-find-a-legit-investment-firm">
								How To Find A Legit Investment Firm?
							</Link>
						</li>
					</ul>
				</li>
				<li>
					<Link to="/about-us">About Us</Link>
				</li>
				<li>
					<a id="scams" style={{ cursor: "pointer" }}>
						Scams <i className="fal fa-angle-down" />
					</a>
					<ul className="sub-menu">
						<li>
							<Link to="/scams/money-transfer-scams">Money Transfer Scams</Link>
						</li>
						<li>
							<Link to="/scams/metatrader-scams">Metatrader Scams</Link>
						</li>
						<li>
							<Link to="/scams/facebook-lottery-scams">
								Facebook Lottery Scams{" "}
							</Link>
						</li>
						<li>
							<Link to="/scams/email-scams">Email Scams</Link>
						</li>
						<li>
							<Link to="/scams/sweepstakes-scams">Sweepstakes Scams</Link>
						</li>
						<li>
							<Link to="/scams/online-banking-scams">Online Banking Scams</Link>
						</li>
						<li>
							<Link to="/scams/investment-scams">Investment Scams</Link>
						</li>
						<li>
							<Link to="/scams/insurance-scams">Insurance Scams</Link>
						</li>
						<li>
							<Link to="/scams/phishing-scams">Phishing Scams</Link>
						</li>
						<li>
							<Link to="/scams/forex-withdrawal-scams">
								Forex Withdrawal Scams
							</Link>
						</li>
						<li>
							<Link to="/scams/ico-scams">Ico Scams</Link>
						</li>
						<li>
							<Link to="/scams/cfd-scams">Cfd Scams</Link>
						</li>
						<li>
							<Link to="/scams/pyramid-scams">Pyramid Scams</Link>
						</li>
						<li>
							<Link to="/scams/forex-scams">Forex Scams</Link>
						</li>
						<li>
							<Link to="/scams/broker-scams">Broker Scams</Link>
						</li>
					</ul>
				</li>
				<li>
					<Link to="/news">News</Link>
				</li>
				<li>
					<Link to="/contact">Contact</Link>
				</li>
				<li>
					<Link to="/analysistool">Analysis Tool</Link>
				</li>
				<li>
					<Link to="/scamsites">Scam Sites</Link>
				</li>
			</ul>
		</>
	);
}

export default Navigation;
