import React from "react";
import IconOne from "../../assets/images/icon/3.png";
import IconTwo from "../../assets/images/icon/iconcomplaint.png";
import IconThree from "../../assets/images/icon/iconsubmit.png";
import IconFour from "../../assets/images/icon/4.png";

function ServicesHomeOne({ className }) {
	return (
		<section
			className={`appie-service-area pt-90 pb-100 ${className}`}
			id="service"
		> 
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-6">
						<div className="appie-section-title text-center">
							<h3 className="appie-title">How We Operate?</h3>
							<p>Understand how we accomplish our goals. </p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-3 col-md-6">
						<div
							className="appie-single-service text-center mt-30 wow animated fadeInUp"
							data-wow-duration="2000ms"
							data-wow-delay="200ms"
						>
							<div className="icon">
								<img src={IconOne} alt="" />
								<span>1</span>
							</div>
							<h4 className="appie-title">Investigate</h4>
							<p>
								Getting a creepy feeling? For you, our professionals will look
								into the legitimacy and reliability of the broker.
							</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div
							className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
							data-wow-duration="2000ms"
							data-wow-delay="400ms"
						>
							<div className="icon">
								<img src={IconTwo} alt="" />
								<span>2</span>
							</div>
							<h4 className="appie-title">Complaint</h4>
							<p>
								Worked with an unreliable broker? We'll make a formal complaint
								and investigate the situation.
							</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div
							className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
							data-wow-duration="2000ms"
							data-wow-delay="600ms"
						>
							<div className="icon">
								<img src={IconThree} alt="" />
								<span>3</span>
							</div>
							<h4 className="appie-title">Submit Our Claim</h4>
							<p>
								With the aid of court proceedings and solid proof, we guarantee
								that you receive what is legally yours.
							</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div
							className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
							data-wow-duration="2000ms"
							data-wow-delay="800ms"
						>
							<div className="icon">
								<img src={IconFour} alt="" />
								<span>4</span>
							</div>
							<h4 className="appie-title">Recover Your Funds</h4>
							<p>
								It all comes to a stop here. Our goal is to win your lawsuit and
								get your money back.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ServicesHomeOne;
